<template>
  <div class="fill-height">
    <v-container class="fill-height p-0">
      <v-img
          :src="require('@/assets/img/main-view.svg')"
          height="70vh"
          fill="red"
          contain
      ></v-img>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Main'
}
</script>
